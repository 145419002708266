<template>
  <div class="invitation-card-warp">
    <div class="title-data">
      <p>邀约海报</p>
      <p class="switch__box">
        <vh-switch
          style="display: block; padding-top: 4px"
          v-model="isOpen"
          @change="changeOpen"
          active-color="#FB3A32"
          inactive-color="#CECECE"
          active-text="开启后，观众可以在观看页面生成邀约海报，邀请好友观看"
        ></vh-switch>
      </p>
      <div class="invitation-look">
        <vh-button
          size="medium"
          plain
          round
          class="transparent-btn"
          :disabled="!isOpen || isImgsLoading"
          @click="downloadCard"
        >
          本地下载
        </vh-button>
      </div>
    </div>
    <div class="invitation-from" v-loading="loading">
      <div class="form-data">
        <invitation-card-edit
          ref="cardEdit"
          :isOpen="isOpen"
          @changeCardInfo="changeCardInfo"
          @switchStatusChange="switchStatusChange"
          @imgLoadCompleted="imgLoadCompleted"
          :sourceId="webinarId"
          :sourceType="1"
          :shareUrl="shareUrl"
        ></invitation-card-edit>
      </div>
      <div class="invitation-show">
        <invitation-card-tmpl
          ref="inviteCardTmplComp"
          class="invitation-card-pc"
          :cardInfo="cardInfo"
          :positionStyle="positionStyle || {}"
          @onImgsLoaded="onImgsLoadingChange"
        ></invitation-card-tmpl>
      </div>
      <div class="white-show" v-show="!isOpen"></div>
    </div>

    <begin-play
      :webinarId="$route.params.str"
      v-if="$route.query.type != 5 && webinarState != 4"
    ></begin-play>
  </div>
</template>
<script>
  import { sessionOrLocal } from '@/utils/utils';
  import beginPlay from '@/components/beginBtn';
  import InvitationCardEdit from './components/InvitationCardEdit.vue'; //数据编辑
  import { InvitationCardTmpl } from '@vhcl/invitation-card';
  import '@vhcl/invitation-card/dist/css/index.css';
  export default {
    data() {
      return {
        isOpen: false, //是否开启邀请卡
        webinarState: sessionOrLocal.get('webinarState'), //活动状态
        webinarId: '', //活动id
        cardInfo: {},
        positionStyle: {},
        isImgsLoading: true,
        shareUrl: '',
        loading: true
      };
    },
    components: {
      beginPlay,
      InvitationCardTmpl,
      InvitationCardEdit
    },
    created() {
      this.webinarId = this.$route.params.str;
      this.shareUrl = `${process.env.VUE_APP_WAP_WATCH}/lives/watch/${this.webinarId}`;
    },
    methods: {
      imgLoadCompleted() {
        this.loading = false;
      },
      onImgsLoadingChange(val) {
        this.isImgsLoading = val;
      },
      switchStatusChange(val) {
        this.isOpen = val;
      },
      changeCardInfo({ cardInfo, positionStyle }) {
        this.cardInfo = cardInfo;
        this.positionStyle = positionStyle;
      },
      //邀请卡开启状态切换
      changeOpen() {
        let params = {
          webinar_id: this.webinarId,
          status: Number(this.isOpen)
        };
        this.$fetch('setCardStatus', params)
          .then(res => {
            this.$vhall_paas_port({
              k: this.isOpen ? 100274 : 100275,
              data: {
                business_uid: sessionOrLocal.get('userId'),
                user_id: '',
                webinar_id: this.webinarId,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            this.$vhMessage({
              message: this.isOpen ? '开启邀约海报成功' : '关闭邀约海报成功',
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || (this.isOpen ? '开启邀约海报失败' : '关闭邀约海报失败'),
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      //本地下载
      downloadCard() {
        const el = this.$refs.inviteCardTmplComp?.$el;
        this.$refs.cardEdit.drawCanvas(el);
      }
    }
  };
</script>
<style lang="less" scoped>
  .invitation-show {
    width: 750px;
    height: 1334px;
    zoom: 1;
    border-radius: 16px;
    border: 1px solid #e2e2e2;
    overflow: hidden;
  }
  .invitation-card-pc {
    transform: scale(0.5);
    transform-origin: 0 0;
  }
  .invitation-card-warp {
    /deep/.vh-form-item__label {
      font-weight: 400;
      color: #1a1a1a;
    }
    /deep/.vh-input .vh-input__count {
      color: #999;
    }
    /deep/.vh-input__count-inner {
      color: #999;
    }
    /deep/.vh-textarea .vh-input__count {
      color: #999;
      font-size: 14px;
    }
    /deep/.invite-card-button {
      width: 106px;
      height: 36px;
    }
    /deep/.vh-input__count {
      line-height: 20px;
      bottom: 7px;
    }
    /deep/.vh-input__inner,
    /deep/.vh-textarea__inner {
      padding: 5px 12px;
      color: #1a1a1a;
    }
    .invitation-from {
      display: flex;
      height: 100%;
      background: #fff;
      padding: 20px 48px 32px 30px;
      position: relative;
      border-radius: 4px;
    }
    .form-data {
      padding: 10px 0 0px;
      margin-right: 64px;
      width: 506px;
      min-width: 506px;
    }
    .data-img {
      width: 320px;
      height: 135px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 10px;
      cursor: pointer;
      .advor_img {
        width: 70px;
        height: 132px;
        position: absolute;
        margin: 0 125px;
        img {
          width: 100%;
          height: 100%;
          // object-fit: scale-down;
        }
      }

      .choseImg {
        width: 100%;
        display: inline-block;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 0px 0px 4px 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        cursor: pointer;
      }
    }
    .image_cropper {
      width: 320px;
      margin-top: 12px;
      &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          color: #595959;
        }
        .wid_block {
          display: inline-block;
          width: 16px;
        }
      }
    }

    .invitation-show {
      margin-top: 10px;
      padding-right: 200px;
      position: relative;
      width: 375px;
      height: 667px;
      p {
        font-size: 14px;
        font-weight: 400;
        color: #1a1a1a;
        padding-bottom: 16px;
      }
    }
    .title-data {
      margin-bottom: 32px;
      position: relative;
      line-height: 30px;
      display: flex;
      p {
        font-size: 22px;
        font-weight: 600;
        color: #1a1a1a;
        padding-right: 8px;
      }
      .invitation-look {
        position: absolute;
        right: 0;
        top: 0;
        ::v-deep .vh-button:last-child {
          margin-left: 10px;
        }
      }
    }
    .sureBtn {
      margin-left: 80px;
    }
    .white-show {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.5);
      z-index: 9;
    }
  }
  .invitation-code {
    width: 132px;
    height: 132px;
    text-align: center;
    img {
      width: 132px;
      height: 132px;
      object-fit: scale-down;
    }
  }
</style>
